$scrollbar-width: 10px;
$scrollbar-track-background-color: #f1f1f1;
$scrollbar-thumb-background-color: #888;
$scrollbar-thumb-hover-background-color: #555;
$scroll-selection-background-color: #17407a;
$scroll-selection-color: #fcbc2b;

@mixin scrollbar-style() {
  ::-webkit-scrollbar {
    width: $scrollbar-width;

    &-track {
      background: $scrollbar-track-background-color;
    }

    &-thumb {
      background: $scrollbar-thumb-background-color;

      &:hover {
        background: $scrollbar-thumb-hover-background-color;
      }
    }
  }
}

@mixin selection-style() {
  ::selection {
    background-color: $scroll-selection-background-color;
    color: $scroll-selection-color;
  }
}
