.pointer {
  cursor: pointer;
}

.disabled,
[disabled] {
  cursor: not-allowed !important;
}

.text-multi-line {
  white-space: pre-wrap !important;
}

.viewBox {
  display: flex;
  flex-direction: column;
  height: 100vh;

  section:first-of-type {
    flex: 1 0 auto;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

.containerFluid {
  width: 100%;
  padding-right: 3.3125rem;
  padding-left: 3.3125rem;
  margin-right: auto;
  margin-left: auto;
}
